.xStyles {
  position: relative;
  height: 150px; /* this can be anything */
  width: 150px;  /* ...but maintain 1:1 aspect ratio */
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.xStyles::before,
.xStyles::after {
  position: absolute;
  content: '';
  width: 100%;
  height: 8px; /* cross thickness */
  background-color: white;
}

.xStyles::before {
  transform: rotate(45deg);
}

.xStyles::after {
  transform: rotate(-45deg);
}

